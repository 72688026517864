import React from "react";
import Layouts from "../components/Layouts";
import Seo from "../components/Seo";
import BlogSlider from "../components/Screens/Blog/BlogSlider";
import { BannerCard } from "../components/Screens/Products/BannerCard";
import { Cta } from "../components/Cta";
import { BlogFeed } from "../components/Screens/Blog/BlogFeed";
import { PageHeader } from "../components/Atomics/PageHeader";
import { graphql, Link } from "gatsby";

const BlogPage = ({ data }) => {
	const posts = data.allWpPost.edges || [];
	return (
		<>
			<Seo title="Blog" />
			<Layouts lang={"en"}>
				<PageHeader miniHeader={"Blog"} />
				<BlogSlider data={data.page.sliderBlog.slidesBlog.slides} />
				<BlogFeed blogPosts={posts} />
				<BannerCard
					bgColor
					Icon={Icon}
					header={data.page.sliderBlog.bannerBoxBlog.title}
					text={data.page.sliderBlog.bannerBoxBlog.text}
					textClass="xl:w-6/12 mx-auto text-xl"
					children={
						<Link to="/careers">
							<button className="py-5 px-20 mt-12 rounded-2xl shadow-lg  font-MazzardBold bg-white text-[#F6B904] relative">
								<span>Careers</span>
							</button>
						</Link>
					}
				/>
				<Cta
					miniHeader="EGYPT's First Nano Financial Service Provider"
					header="Powering Access to Digital Financial Possibilities"
					subHeader="Kashat serves as the entry point to financial inclusion for the un- and underbanked population in Egypt by offering instant, small, short-term, productive loans."
				/>
			</Layouts>
		</>
	);
};
export default BlogPage;
export const BlogEnglishQuery = graphql`
	query BlogEnglish {
		allWpPost(filter: { language: { code: { eq: EN }, slug: { ne: null } } }) {
			edges {
				node {
					id
					language {
						code
						locale
					}
					featuredImage {
						node {
							link
							date
							localFile {
								childImageSharp {
									gatsbyImageData(width: 1920, placeholder: BLURRED)
								}
							}
						}
					}
					tags {
						nodes {
							name
						}
					}
					date
					title
					articleAuthor {
						profilePicArticleAuthor {
							link
							date
							localFile {
								childImageSharp {
									gatsbyImageData(width: 120, placeholder: BLURRED)
								}
							}
						}
						authorName
						authorTitle
					}
					slug
					title
				}
			}
		}

		page: wpPage(databaseId: { eq: 548 }) {
			title
			sliderBlog {
				slidesBlog {
					slides {
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 1920, placeholder: BLURRED)
								}
							}
						}
						title
						text
					}
				}
				bannerBoxBlog {
					title
					text
				}
			}
		}
	}
`;

const Icon = (
	<svg
		width="72"
		height="72"
		viewBox="0 0 72 72"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="72" height="72" rx="16" fill="#202020" fill-opacity="0.2" />
		<path
			d="M49.953 47.7648C51.103 46.6965 51.8346 45.1882 51.8346 43.4998C51.8346 40.2832 49.218 37.6665 46.0013 37.6665C42.7846 37.6665 40.168 40.2832 40.168 43.4998C40.168 45.1882 40.8996 46.6965 42.0496 47.7648C39.5863 48.6548 37.668 50.3682 37.668 52.6665H41.0013C41.0013 51.6698 43.3563 50.4048 46.0013 50.4048C48.6463 50.4048 51.0013 51.6715 51.0013 52.6665H54.3346C54.3346 50.3665 52.4163 48.6548 49.953 47.7648ZM46.0013 40.9998C47.3796 40.9998 48.5013 42.1215 48.5013 43.4998C48.5013 44.8782 47.3796 45.9998 46.0013 45.9998C44.623 45.9998 43.5013 44.8782 43.5013 43.4998C43.5013 42.1215 44.623 40.9998 46.0013 40.9998Z"
			fill="#12022F"
		/>
		<path
			d="M29.953 47.7648C31.103 46.6965 31.8346 45.1882 31.8346 43.4998C31.8346 40.2832 29.218 37.6665 26.0013 37.6665C22.7846 37.6665 20.168 40.2832 20.168 43.4998C20.168 45.1882 20.8996 46.6965 22.0496 47.7648C19.5863 48.6548 17.668 50.3665 17.668 52.6665H21.0013C21.0013 51.6698 23.3563 50.4048 26.0013 50.4048C28.6463 50.4048 31.0013 51.6715 31.0013 52.6665H34.3346C34.3346 50.3665 32.4163 48.6548 29.953 47.7648ZM26.0013 40.9998C27.3796 40.9998 28.5013 42.1215 28.5013 43.4998C28.5013 44.8782 27.3796 45.9998 26.0013 45.9998C24.623 45.9998 23.5013 44.8782 23.5013 43.4998C23.5013 42.1215 24.623 40.9998 26.0013 40.9998Z"
			fill="#12022F"
		/>
		<path
			d="M41.0013 34.3333H44.3346C44.3346 32.0333 42.4163 30.3216 39.953 29.4316C41.103 28.3633 41.8346 26.8549 41.8346 25.1666C41.8346 21.9499 39.218 19.3333 36.0013 19.3333C32.7846 19.3333 30.168 21.9499 30.168 25.1666C30.168 26.8549 30.8996 28.3633 32.0496 29.4316C29.5863 30.3216 27.668 32.0333 27.668 34.3333H31.0013C31.0013 33.3366 33.3563 32.0716 36.0013 32.0716C38.6463 32.0716 41.0013 33.3366 41.0013 34.3333ZM36.0013 22.6666C37.3796 22.6666 38.5013 23.7883 38.5013 25.1666C38.5013 26.5449 37.3796 27.6666 36.0013 27.6666C34.623 27.6666 33.5013 26.5449 33.5013 25.1666C33.5013 23.7883 34.623 22.6666 36.0013 22.6666Z"
			fill="#12022F"
		/>
	</svg>
);
